import { FC, useState, useEffect } from 'react';
import {
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Token } from '@deliquid/token-list';

import { TokenSearchContainer } from './styles';

export interface TokenSearchProps {
  tokens: Token[] | undefined;
  onTokenClick: (token: Token) => void;
}

const TokenSearch: FC<TokenSearchProps> = ({ tokens, onTokenClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTokens, setFilteredTokens] = useState<Token[] | undefined>(
    tokens
  );

  useEffect(() => {
    setFilteredTokens(tokens);
  }, [tokens]);

  const onChangeSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const filterTokens = tokens?.filter((token) => {
      return (
        token.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.symbol.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredTokens(filterTokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const tokenList = filteredTokens?.map((token) => {
    return (
      <ListItem
        dense
        button
        key={token.chain + token.symbol}
        onClick={() => onTokenClick(token)}
      >
        <ListItemIcon>
          <img src={token.logoURI} alt={token.symbol} width='30px' />
        </ListItemIcon>
        <ListItemText primary={token.name} secondary={token.symbol} />
      </ListItem>
    );
  });

  return (
    <TokenSearchContainer>
      <TextField
        id='outlined-basic'
        variant='outlined'
        label='Token'
        value={searchTerm}
        onChange={onChangeSearchTerm}
        fullWidth
      />
      <List
        sx={{
          width: '100%',
          overflow: 'auto',
          maxHeight: '510px',
        }}
      >
        {tokenList}
      </List>
    </TokenSearchContainer>
  );
};

export default TokenSearch;
