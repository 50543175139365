import { styled } from '@mui/material/styles';

export const TokenInfoContainer = styled('div')({
  margin: '4em',
  textAlign: 'left',
});

export const GreyText = styled('span')({
  fontWeight: 'bold',
  color: 'grey',
});
