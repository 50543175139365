import { styled } from '@mui/material/styles';

export const SearchTokenContainer = styled('div')({
  width: '100%',
  height: '600px',
  maxWidth: '1100px',
  margin: '0 auto',
  borderRadius: '10px',
  boxShadow:
    'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
});
