import { FC } from 'react';

import { TopNavBar } from 'src/components';
import { SearchToken } from 'src/views';
import './App.css';

const App: FC = () => {
  return (
    <div className='App'>
      <TopNavBar />
      <SearchToken />
    </div>
  );
};

export default App;
