import { FC } from 'react';
import { AppBar, Container, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

import DeLiquidLogo from 'src/assets/logo/logo.png';
import { StyledLogo } from './styles';

const TopNavBar: FC = () => {
  return (
    <AppBar position='static' color='inherit'>
      <Container maxWidth='xl' sx={{ display: 'flex' }}>
        <Toolbar>
          <Link to='/'>
            <StyledLogo src={DeLiquidLogo} alt='DeLiquid Logo' />
          </Link>
          <h2 style={{ margin: '0 0 5px 0', color: 'grey' }}>Token List</h2>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopNavBar;
