import { FC } from 'react';
import { Token } from '@deliquid/token-list';
import { Chip, Button, Link } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

import { TokenInfoContainer, GreyText } from './styles';

export interface TokenInfoProps {
  token: Token;
}

// configs
const stringKeys = ['name', 'symbol', 'chain', 'address', 'decimals'];
const linkKeys = ['website'];

const TokenInfo: FC<TokenInfoProps> = ({ token }) => {
  // eslint-disable-next-line
  const tokenInfo = Object.entries(token).map(([key, value]) => {
    // display string info
    if (stringKeys.includes(key)) {
      return (
        <div key={key}>
          <GreyText>{key.charAt(0).toUpperCase() + key.slice(1)}: </GreyText>
          <span>{value}</span>
        </div>
      );
    }

    // display link info
    if (linkKeys.includes(key)) {
      return (
        <div key={key}>
          <GreyText>{key.charAt(0).toUpperCase() + key.slice(1)}: </GreyText>
          <Link href={value} target='_blank' rel='noreferrer noopener'>
            {value}
          </Link>
        </div>
      );
    }
  });

  const onCopyClick = () => {
    navigator.clipboard.writeText(JSON.stringify(token));
  };

  return (
    <TokenInfoContainer>
      <img src={token.logoURI} alt={token.name} width='50px' />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ marginRight: 10 }}>{token.symbol}</h2>
        <Chip label={token.type} size='small'></Chip>
      </div>

      <div>{tokenInfo}</div>
      <br />
      <Button
        variant='outlined'
        color='primary'
        startIcon={<ContentCopy />}
        onClick={onCopyClick}
      >
        Copy JSON
      </Button>
    </TokenInfoContainer>
  );
};

export default TokenInfo;
