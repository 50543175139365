import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TokenList, Token } from '@deliquid/token-list';

import { SearchTokenContainer } from './styles';
import { TokenSearch, TokenInfo } from 'src/components';

const SearchToken: FC = () => {
  const [selectedToken, setSelectedToken] = useState<Token | undefined>();
  const [tokens, setTokens] = useState<Token[] | undefined>();

  const fetchTokens = async () => {
    const tokenList = new TokenList();
    const tokens = await tokenList.getAllTokens();
    setTokens(tokens);
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  const onTokenClick = (token: Token) => {
    setSelectedToken(token);
  };

  return (
    <div>
      <SearchTokenContainer>
        <Grid container>
          <Grid item xs={12} md={5}>
            <TokenSearch tokens={tokens} onTokenClick={onTokenClick} />
          </Grid>
          <Grid item xs={12} md={7}>
            {selectedToken && <TokenInfo token={selectedToken} />}
          </Grid>
        </Grid>
      </SearchTokenContainer>
    </div>
  );
};

export default SearchToken;
